<template>
  <div class="page">
    <nav-top :index="2" @cityLoaded="handleCityLoaded"></nav-top>
    <div class="crumbs-info">
      <div class="crumbs-search">
        <div class="crumbs">
          <div class="item">
            <router-link to="#">首页</router-link>
            <span class="right-arrows"></span>
          </div>
          <div class="item active">
            <router-link to="#">楼市热点</router-link>
          </div>
        </div>
        <div class="search">
          <div class="search-text">
            <div class="text">
              <input type="text" placeholder="请输入关键词" v-model="keyword">
            </div>
            <div class="btn" @click="handleSearchClick">
              <img src="/static/images/fdj_ico2.png" alt="">
            </div>
          </div>
          <router-link class="report-btn" to="/report">
            <span>快速报备</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="banner-info">
      <div class="info">
        <div class="title">
          <span>楼市热点</span>
        </div>
        <div class="desc">
          <span>NEWS HIGHKIGHTS</span>
        </div>
        <div class="more">
          <img src="/static/images/xbjt_ico.png" alt="">
        </div>
      </div>
    </div>

    <div class="plate-info">
      <div class="all-plate">
        <div class="title">
          <span>全部板块</span>
          <span>/ All plates</span>
        </div>
      </div>
      <div class="plate-list" v-loading="loading">
        <div class="plate" v-for="(cate_item,cate_index) in cate_list" :key="cate_index">
          <div class="plate-title">
            <span>{{ cate_item.name }}</span>
          </div>
          <div class="hot-list">
            <div class="list" v-for="(article_item,article_index) in article_list[cate_index]" :key="article_index"
                 @click="handleArticleClick(article_item)">
              <div class="list-image" v-if="article_index < 3">
                <div class="pic">
                  <img :src="article_item.image" alt="">
                </div>
                <div class="item">
                  <div class="title">
                    <span>{{ article_item.title }}</span>
                  </div>
                  <div class="date-tag">
                    <div class="date">
                      <span>{{ article_item.create_date }}</span>
                    </div>
                    <div class="tag">
                      <span class="new" v-if="article_item.tag === 1">{{ article_item.tag_text }}</span>
                      <span class="fff" v-if="article_item.tag === 2">{{ article_item.tag_text }}</span>
                      <span class="hot" v-if="article_item.tag === 3">{{ article_item.tag_text }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-text" v-else>
                <span class="dot"></span>
                <span>{{ article_item.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavTop from '../../components/NavTop/NavTop'
import FooterBar from '../../components/FooterBar/FooterBar'

export default {
  name: 'hotspot',
  components: {FooterBar, NavTop},
  data() {
    return {
      loading:false,
      keyword:'',
      cate_list: [],
      article_list: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    async handleCityLoaded() {
      await this.init()
    },
    async init() {
      try {
        this.loading = true
        await this.fetchCateList()

        let requestList = []

        for (let item of this.cate_list) {
          requestList.push(new Promise((resolve, reject) => {
            this.fetchArticleList(item.id).then((res) => {
              resolve(res)
            }).catch((err) => {
              reject(err)
            })
          }))
        }

        this.article_list = await Promise.all(requestList)
      }catch (e){
        console.log(e)
      }finally {
        this.loading= false
      }
    },
    async fetchCateList() {
      let res = await this.http({
        url: '/api/article/hotArticleCateList',
        method: 'GET',
      })

      this.cate_list = res.data
    },
    async fetchArticleList(cateId) {
      let res = await this.http({
        url: '/api/article/hotList',
        method: 'GET',
        params: {
          cate_id: cateId,
          keyword:this.keyword
        }
      })
      return res.data.list.slice(0,6)
    },
    handleArticleClick(item) {
      this.$router.push({
        path: '/hotspotDetails',
        query: {id: item.id}
      })
    },
    handleSearchClick(){
      this.init()
    }
  }
}
</script>

<style scoped lang="scss">
@import "hotspot.scss";
</style>
